
import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
import featuredJobsData from '@/data/featuredJobs.json'

export default {
  name: 'JobFeed',
  components:{SsrCarousel},
  props: {
    limit: {
      type: String,
      default: '10',
    },
    featured: {
      type: Boolean,
      default: false,
    },
    items:{
      type: String,
      default: '3',
    },
    items_400:{
      type: String,
      default: '1',
    },
    items_740:{
      type: String,
      default: '2',
    },
    items_900:{
      type: String,
      default: '3',
    },
    items_1040:{
      type: String,
      default: '3',
    },
    fwPage: {
      type:Boolean,
      default:false,
    },
  },
  data() {
    return {
      jobs: [],
      settings: {
        autoplay: false,
        slidesToShow: Number(this.$props.items),
        slidesToScroll: Number(this.$props.items),
        dots: true,
        infinite: false,
        arrows:true ,
        // centerMode:true,
        centerPadding: "30px",
        responsive: [
          {
            breakpoint: 400,
            settings: {
              slidesToShow: Number(this.$props.items_400),
              slidesToScroll: Number(this.$props.items_400),
            }
          },
          {
            breakpoint: 740,
            settings: {
              slidesToShow: Number(this.$props.items_740),
              slidesToScroll: Number(this.$props.items_740),
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: Number(this.$props.items_900),
              slidesToScroll: Number(this.$props.items_900),
            }
          },
          {
            breakpoint: 1040,
            settings: {
              slidesToShow: Number(this.$props.items_1040),
              slidesToScroll: Number(this.$props.items_1040),
            }
          },
        ]
      },
    }
  },
  async fetch() {
    const data = await featuredJobsData
    this.jobs = data
},
computed:{
  setTarget(){
    if (this.fwPage){
      return "_BLANK"
    } else {
      return ""
    }
  }
},

  methods: {
    goNext() {
      this.$refs.tinySlider.slider.goTo('next')
    },
    goPrevious() {
      this.$refs.tinySlider.slider.goTo('prev')
    },
}
}
